<template>
  <v-list-item>
    <v-list-item-avatar v-if="avatar">
      <v-img :src="avatar"></v-img>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        {{tokenName}}
      </v-list-item-title>

      <v-list-item-subtitle>
        <v-chip x-small class="mt-2" color="green" text-color="white" v-if="isMaintainer">
          {{'ROLE_MAINTAINER'| t}}
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script lang="babel" type="text/babel">
import {mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters({
      tokenName: 'token/name',
      isMaintainer: 'token/isMaintainer',
      avatar: 'token/avatar',
    })
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>